import React, { Component } from 'react';
import './Portfolio.css';
import { NavLink } from 'react-router-dom';
import ApiConfig from '../api/ApiConfig';
import { join } from 'path';
import { parseWord } from '../app/Languages';
import PortfolioTypes from '../app/PortfolioTypes';
import Copyright from './Copyright';

const weddingRefWidth = 280 + 25;
const minColCount = 1;

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.calcColCount = this.calcColCount.bind(this);
    this.rowsRef = [];
    this.weddingsRef = React.createRef();
  }

  state = {
    loaded: false,
  };

  calculateRows() {
    const { portfolio: { weddings, photosessions }, colCount } = this.props;
    const weddingRows = [];
    const photosessionRows = [];
    for (let i = 0; i < weddings.length; i += colCount) {
      weddingRows.push(weddings.slice(i, i + colCount));
    }
    for (let i = 0; i < photosessions.length; i += colCount) {
      photosessionRows.push(photosessions.slice(i, i + colCount));
    }
    this.props.onSetRows(weddingRows, photosessionRows);
  }

  calcColCount() {
    const weddingsRefWidth = this.weddingsRef.current.clientWidth;
    const colCount = Math.max(minColCount, Math.floor(weddingsRefWidth / weddingRefWidth));
    this.props.onSetColCount(colCount);
  }

  setRowsWidth() {
    this.rowsRef.forEach(rowRef => {
      if (!rowRef) return;
      rowRef.style.width = (weddingRefWidth * this.props.colCount) + 'px';
    });
  }

  displayWedding(portfolioType, wedding, index) {
    const { match, language } = this.props;
    return (
      <NavLink key={index} className="weddings-wedding-link"
        to={join(match.url, portfolioType, wedding.id)}>
        <div className="weddings-wedding">
          <img alt="wedding" className="weddings-wedding-cover"
            src={join(ApiConfig.Portfolio, wedding.id, portfolioType, 'cover')} />
          <div className="weddings-wedding-hover">
            <div className="weddings-wedding-dark"></div>
            <div className="weddings-wedding-name">
              {parseWord(language, wedding.name)}
            </div>
          </div>
        </div>
      </NavLink>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.onSetPortfolioScrollPosition(0);
    }
    if (this.props.portfolio !== prevProps.portfolio) {
      this.calcColCount();
    }
    if (this.props.colCount !== prevProps.colCount) {
      this.calculateRows();
    }
    if (this.props.weddingRows !== prevProps.weddingRows ||
      this.props.photosessionRows !== prevProps.photosessionRows) {
      this.setRowsWidth();
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    }, () => {
      window.scrollTo(0, this.props.portfolioScrollPosition);
      window.addEventListener('resize', this.calcColCount);
      if (this.props.portfolio) {
        this.calcColCount();
      }
      this.setRowsWidth();
    });
  }

  componentWillUnmount() {
    this.props.onSetPortfolioScrollPosition(window.scrollY);
    window.removeEventListener('resize', this.calcColCount);
  }

  render() {
    const { language, weddingRows, photosessionRows } = this.props;
    const { loaded } = this.state;
    if (!loaded) return <div />;
    return (
      <>
        <div className="portfolio">
          <div className="portfolio-container">
            <div className="weddings" ref={this.weddingsRef}>
              {weddingRows.map((row, index) =>
                <div key={index} className="weddings-row" ref={r => this.rowsRef.push(r)}>
                  {row.map((wedding, index) =>
                    this.displayWedding(PortfolioTypes.Wedding, wedding, index))}
                </div>
              )}
            </div>
          </div>
          <div className="portfolio-container portfolio-container-photosessions">
            <div className="photosessions">
              {photosessionRows.map((row, index) =>
                <div key={index} className="weddings-row" ref={r => this.rowsRef.push(r)}>
                  {row.map((wedding, index) =>
                    this.displayWedding(PortfolioTypes.Photosession, wedding, index))}
                </div>
              )}
            </div>
          </div>
        </div>
        <Copyright className="portfolio-copyright" language={language} />
      </>
    );
  }
}

export default Portfolio;