import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './TopPanel.css';
import Links from '../app/Links';
import Languages, { parseWord } from '../app/Languages';
import LinkNames from '../app/LinkNames';

class TopPanel extends Component {
  handleClickOnPortfolio() {
    this.props.onSetPortfolioScrollPosition(0);
  }

  displayMenu(forActivePage) {
    const { language, menuOpened } = this.props;
    return (
      <div className={'top-panel-links-container top-panel-menu' +
        (forActivePage ? ' top-panel-active-page' : ' top-panel-menu-page')}>
        <div className={'top-panel-links top-panel-menu-items' +
          (menuOpened ? ' top-panel-menu-items-opened' : '')}>
          <NavLink exact className={'top-panel-link-name-container top-panel-menu-items-item' +
            (menuOpened ? ' top-panel-link-active-opened' : '')}
            activeClassName="top-panel-link-active" to={Links.Gallery}>
            <div className={'top-panel-menu-items-item-sub top-panel-link top-panel-link-name' +
              ' top-panel-link-firstname'}>{parseWord(language, LinkNames.Gallery[0])}</div>
            <div className={'top-panel-menu-items-item-sub top-panel-link' +
              ' top-panel-link-name'}>{parseWord(language, LinkNames.Gallery[1])}</div>
          </NavLink>
          <div className="top-panel-link-vline-container">
            <div className="top-panel-vline"></div>
          </div>
          <NavLink className={'top-panel-link top-panel-menu-items-item' +
            (menuOpened ? ' top-panel-link-active-opened' : '')}
            activeClassName="top-panel-link-active" to={Links.Portfolio}
            onClick={() => this.handleClickOnPortfolio()}>
            {parseWord(language, LinkNames.Weddings)}
          </NavLink>
          <div className="top-panel-link-vline-container">
            <div className="top-panel-vline"></div>
          </div>
          <NavLink className={'top-panel-link top-panel-menu-items-item' +
            (menuOpened ? ' top-panel-link-active-opened' : '')}
            activeClassName="top-panel-link-active" to={Links.Contacts}>
            {parseWord(language, LinkNames.Contacts)}
          </NavLink>
          <div className="top-panel-link-vline-container">
            <div className="top-panel-vline"></div>
          </div>
          <div className={'top-panel-social' + (forActivePage ? ' top-panel-social-hidden' : '')}>
            <div className="top-panel-social-inside">
              <a className="tp-soc-im-container tp-soc-im-mywed-container"
                href="https://danylovych.mywed.com" target="_blank" title="mywed"
                rel="noopener noreferrer"
                onContextMenu={e => e.preventDefault()}
                onDragStart={e => e.preventDefault()}>
                <img alt="mywed" className="tp-soc-im" src="/images/mywed-hover.svg" />
                <img alt="mywed" className="tp-soc-im tp-soc-im-hv" src="/images/mywed.svg" />
              </a>
              <a className="tp-soc-im-container"
                href="https://fb.com/yura.danylovych" target="_blank" title="facebook"
                rel="noopener noreferrer"
                onContextMenu={e => e.preventDefault()}
                onDragStart={e => e.preventDefault()}>
                <img alt="facebook" className="tp-soc-im" src="/images/facebook-hover.svg" />
                <img alt="facebook" className="tp-soc-im tp-soc-im-hv" src="/images/facebook.svg" />
              </a>
              <a className="tp-soc-im-container tp-soc-im-instagram-container"
                href="https://instagram.com/yura.danylovych/" target="_blank" title="instagram"
                rel="noopener noreferrer"
                onContextMenu={e => e.preventDefault()}
                onDragStart={e => e.preventDefault()}>
                <img alt="instagram" className="tp-soc-im" src="/images/instagram-hover.svg" />
                <img alt="instagram" className="tp-soc-im tp-soc-im-hv" src="/images/instagram.svg" />
              </a>
              <a className="tp-soc-im-container tp-soc-im-telegram-container"
                href="https://t.me/yura_danylovych" target="_blank" title="telegram"
                rel="noopener noreferrer"
                onContextMenu={e => e.preventDefault()}
                onDragStart={e => e.preventDefault()}>
                <img alt="telegram" className="tp-soc-im" src="/images/telegram-hover.svg" />
                <img alt="telegram" className="tp-soc-im tp-soc-im-hv" src="/images/telegram.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { language, menuOpened } = this.props;
    const languages = Object.keys(Languages);
    const index = languages.indexOf(language);
    const languageName = languages[index === languages.length - 1 ? 0 : index + 1];
    return (
      <div className="top-panel">
        {this.displayMenu(true)}
        {this.displayMenu(false)}
        <div className="top-panel-menu-icon" onClick={e => this.props.onToggleMenu(e)}>
          <img alt="menu" className={'top-panel-menu-icon' +
            (menuOpened ? ' top-panel-menu-icon-hidden' : '')} src="/images/menu.svg" />
          <img alt="menu" className={'top-panel-menu-icon' +
            (menuOpened ? '' : ' top-panel-menu-icon-hidden')} src="/images/menu-active.svg" />
        </div>
        <div className="top-panel-language-switch" onClick={() => this.props.onToggleLanguage()}>
          {Languages[languageName]}
        </div>
      </div>
    );
  }
}

export default TopPanel;