import React, { Component } from 'react';
import './Gallery.css';
import GalleryApi from '../api/GalleryApi';
import { Helmet } from 'react-helmet';
import { join } from 'path';
import ApiConfig from '../api/ApiConfig';

const MIN_TRANSITION_DURATION = 300;
const CIRCLE_WIDTH = 10;
const CIRCLE_MARGIN = CIRCLE_WIDTH / 2;
const MAIN_CIRCLES_COUNT = 5;
const SIDE_CIRCLES_COUNT = 2;
const AUTO_SCROLL_INTERVAL = 4000;
const AUTO_SCROLL_DELAY_INTERVAL = 10000;

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.handleAutoScroll = this.handleAutoScroll.bind(this);
  }

  state = {
    images: [],
    circlesPosition: 0,
    index: 0,
    prevIndexForDuration: 0,
  };

  autoScroll;
  autoScrollDelay;

  styles = (
    <Helmet>
      <style>
        {`
          :root {
            --gallery-main-circles-count: ${MAIN_CIRCLES_COUNT};
            --gallery-side-circles-count: ${SIDE_CIRCLES_COUNT};
            --gallery-circle-width: ${CIRCLE_WIDTH}px;
            --gallery-circle-margin: ${CIRCLE_MARGIN}px;
          }
        `}
      </style>
    </Helmet>
  );

  handleSelectImage(index) {
    this.changeState(index, this.state.index);
  }

  handlePrev() {
    const index = this.state.index;
    const length = this.state.images.length;
    const prevIndexForDuration = index === 0 ? length - 2 : index;
    this.changeState(index === 0 ? length - 1 : index - 1, prevIndexForDuration);
  }

  handleNext(stopAutoScroll = true) {
    const index = this.state.index;
    const length = this.state.images.length;
    const prevIndexForDuration = index === length - 1 ? 1 : index;
    this.changeState(index === length - 1 ? 0 : index + 1, prevIndexForDuration, stopAutoScroll);
  }

  handleKeyDown(keyDown) {
    switch (keyDown) {
      case 'ArrowLeft':
        this.handlePrev();
        break;
      case 'ArrowRight':
        this.handleNext();
        break;
      default:
        break;
    }
  }

  handleAutoScroll() {
    if (this.state.index === this.state.images.length - 1) {
      this.changeState(0, 1, false);
    } else {
      this.handleNext(false);
    }
  }

  startAutoScroll() {
    this.autoScroll = setInterval(this.handleAutoScroll, AUTO_SCROLL_INTERVAL);
  }

  delayAutoScroll() {
    this.autoScrollDelay = setTimeout(() => {
      this.handleAutoScroll();
      this.startAutoScroll();
    }, AUTO_SCROLL_DELAY_INTERVAL);
  }

  stopAutoScrollAndDelay() {
    clearInterval(this.autoScroll);
    clearTimeout(this.autoScrollDelay);
  }

  calculateCirclesPosition(index, circlesPosition) {
    if (index <= circlesPosition - 1) {
      return index;
    } else if (index >= circlesPosition + MAIN_CIRCLES_COUNT) {
      return index - MAIN_CIRCLES_COUNT + 1;
    } else {
      return circlesPosition;
    }
  }

  changeState(index, prevIndexForDuration, stopAutoScroll = true) {
    if (stopAutoScroll) {
      this.stopAutoScrollAndDelay();
      this.delayAutoScroll();
    }
    const circlesPosition = this.calculateCirclesPosition(index, this.state.circlesPosition);
    this.setState({
      index,
      prevIndexForDuration,
      circlesPosition,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.stopAutoScrollAndDelay();
      this.startAutoScroll();
      this.changeState(0, 1, false);
    }
  }

  componentDidMount() {
    document.onkeydown = ({ key }) => this.handleKeyDown(key);
    GalleryApi.get(images => this.setState({
      images,
    }, this.startAutoScroll));
  }

  componentWillUnmount() {
    window.stop();
    document.onkeydown = null;
    this.stopAutoScrollAndDelay();
    this.props.onSetPortfolioScrollPosition(0);
  }

  render() {
    const imagesHost = process.env.REACT_APP_IMAGES_HOST ? process.env.REACT_APP_IMAGES_HOST : '';
    const { images, circlesPosition, prevIndexForDuration, index } = this.state;
    const translateImages = index * -100;
    const translateCircles = (CIRCLE_WIDTH + CIRCLE_MARGIN * 2) * (circlesPosition - SIDE_CIRCLES_COUNT);
    const transitionDuration = Math.log2(Math.abs(index - prevIndexForDuration) + 1) * MIN_TRANSITION_DURATION;
    const circleScale = i => 1 / (Math.abs(circlesPosition - this.calculateCirclesPosition(i, circlesPosition)) + 1);
    return (
      <>
        {this.styles}
        <div className="gallery">
          <div className="gallery-images-container">
            <div className="gallery-images" style={{
              transform: `translate3d(${translateImages}%, 0, 0)`,
              transitionDuration: `${transitionDuration}ms`,
            }}>
              {images.map((image, i) =>
                <img key={i} alt="weddings" className="gallery-image" style={{
                  left: `${i * 100}%`,
                }}
                  src={imagesHost + join(ApiConfig.Gallery, image)} onContextMenu={e => e.preventDefault()}
                  onDragStart={e => e.preventDefault()} />
              )}
            </div>
          </div>
          <div className="gallery-image-nav" onClick={() => this.handlePrev()}></div>
          <div className="gallery-image-nav gallery-image-next" onClick={() => this.handleNext()}></div>
          <div className="gallery-select-panel">
            <div className="gallery-circles-container">
              <div className="gallery-circles" style={{
                transform: `translateX(${-translateCircles}px)`,
                transitionDuration: `${transitionDuration}ms`,
              }}>
                {images.map((image, i) =>
                  <div key={i} className="gallery-circle-container" onClick={() => this.handleSelectImage(i)}>
                    <div className={'gallery-circle' +
                      (i === index ? ' gallery-circle-active' : '')} style={{
                        transform: `scale(${circleScale(i)})`,
                        transitionDuration: `${transitionDuration}ms`,
                      }}></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Gallery;