import Axios from 'axios';
import ApiConfig from './ApiConfig';

const path = ApiConfig.Portfolio;

const PortfolioApi = {
  portfolio: cb => {
    Axios.get(path).then(({ data }) => cb(data));
  },
  wedding: (type, id, cb) => {
    Axios.get(path + '/' + id + '/type/' + type).then(({ data }) => cb(data));
  },
};

export default PortfolioApi;