const Languages = {
  Ukrainian: 'ua',
  English: 'en',
};

export const parseWord = (language, word) => {
  const languages = Object.keys(Languages);
  const translations = word.split(',');
  return translations[languages.indexOf(language)];
};

export default Languages;