import Axios from 'axios';
import ApiConfig from './ApiConfig';

const path = ApiConfig.Gallery;

const GalleryApi = {
  get: cb => {
    Axios.get(path).then(({ data }) => cb(data));
  },
};

export default GalleryApi;