import ReactGA from 'react-ga';

const debug = process.env.NODE_ENV !== 'production';

class GoogleAnalytics {
  constructor(identifier) {
    this.identifier = identifier;
  }

  track(location) {
    ReactGA.pageview(location.pathname);
  }

  run(history) {
    ReactGA.initialize(this.identifier, { debug });
    this.track(history.location);
    history.listen(location => this.track(location));
  }
}

export default GoogleAnalytics;