import React, { Component } from 'react';
import './Main.css';
import { Switch, Route } from 'react-router-dom';
import TopPanel from './TopPanel';
import Links from '../app/Links';
import Gallery from './Gallery';
import Portfolio from './Portfolio';
import Contacts from './Contacts';
import Wedding from './weddings/Wedding';
import Languages from '../app/Languages';
import { join } from 'path';
import PortfolioApi from '../api/PortfolioApi';

class Main extends Component {
  state = {
    language: Object.keys(Languages)[0],
    menuOpened: false,
    portfolio: null,
    weddingRows: [],
    photosessionRows: [],
    colCount: 0,
    portfolioScrollPosition: 0,
  };

  handleToggleLanguage() {
    let { language } = this.state;
    const languages = Object.keys(Languages);
    const index = languages.indexOf(language);
    language = languages[index === languages.length - 1 ? 0 : index + 1];
    this.setState({
      language,
    });
  }

  handleToggleMenu(e) {
    e.stopPropagation();
    this.setState({
      menuOpened: !this.state.menuOpened,
    });
  }

  handleCloseMenu() {
    this.setState({
      menuOpened: false,
    });
  }

  handleSetRows(weddingRows, photosessionRows) {
    this.setState({
      weddingRows,
      photosessionRows,
    });
  }

  handleSetColCount(colCount) {
    this.setState({
      colCount,
    });
  }

  handleSetPortfolioScrollPosition(portfolioScrollPosition) {
    this.setState({
      portfolioScrollPosition,
    });
  }

  componentDidMount() {
    PortfolioApi.portfolio(portfolio => this.setState({
      portfolio,
    }));
  }

  render() {
    const { language, menuOpened, portfolio, weddingRows, photosessionRows, colCount,
      portfolioScrollPosition } = this.state;
    return (
      <div className="main" onClick={() => this.handleCloseMenu()}>
        <TopPanel language={language} menuOpened={menuOpened}
          onToggleMenu={e => this.handleToggleMenu(e)}
          onToggleLanguage={() => this.handleToggleLanguage()}
          onSetPortfolioScrollPosition={portfolioScrollPosition =>
            this.handleSetPortfolioScrollPosition(portfolioScrollPosition)} />
        <Switch>
          <Route path={Links.Gallery} exact render={props =>
            <Gallery {...props} language={language}
              onSetPortfolioScrollPosition={portfolioScrollPosition =>
                this.handleSetPortfolioScrollPosition(portfolioScrollPosition)} />} />
          <Route path={Links.Portfolio} exact render={props =>
            <Portfolio {...props} language={language}
              portfolio={portfolio}
              weddingRows={weddingRows}
              photosessionRows={photosessionRows}
              colCount={colCount}
              onSetRows={(weddingRows, photosessionRows) =>
                this.handleSetRows(weddingRows, photosessionRows)}
              onSetColCount={colCount => this.handleSetColCount(colCount)}
              portfolioScrollPosition={portfolioScrollPosition}
              onSetPortfolioScrollPosition={portfolioScrollPosition =>
                this.handleSetPortfolioScrollPosition(portfolioScrollPosition)} />} />
          <Route path={join(Links.Portfolio, '/:type/:id')}
            render={(props) => <Wedding {...props} language={language} />} />
          <Route path={Links.Contacts} render={props =>
            <Contacts {...props} language={language}
              onSetPortfolioScrollPosition={portfolioScrollPosition =>
                this.handleSetPortfolioScrollPosition(portfolioScrollPosition)} />} />
        </Switch>
      </div>
    );
  }
}

export default Main;
