import React, { Component } from 'react';
import './Wedding.css';
import PortfolioApi from '../../api/PortfolioApi';
import { join } from 'path';
import ApiConfig from '../../api/ApiConfig';
import { ResizeObserver } from 'resize-observer';
import { parseWord } from '../../app/Languages';
import Copyright from '../Copyright';

const space = 4;
const h = (w, W, H) => w / W.reduce((h, W, i) => h + W / H[i], 0);

class Wedding extends Component {
  constructor(props) {
    super(props);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.weddingImagesRef = React.createRef();
    this.imagesRef = new Map();
    this.resizeObserver = new ResizeObserver(() => this.calcHeights());
    this.state = {
      name: '',
      rows: [],
    };
  }

  getId() {
    return this.props.match.params.id;
  }

  getType() {
    return this.props.match.params.type;
  }

  handleWindowResize() {
    this.resizeObserver.unobserve(document.body);
    this.calcHeights();
    this.resizeObserver.observe(document.body);
  }

  calcHeights() {
    this.state.rows.forEach(row => {
      const w = this.weddingImagesRef.current.clientWidth - (row.length - 1) * space;
      const S = row.map(({ size }) => size);
      const height = h(w, S.map(s => s.width), S.map(s => s.height));
      row.forEach(image => {
        const size = image.size;
        const width = height * size.width / size.height;
        const imageRef = this.imagesRef.get(image);
        imageRef.style.width = width + 'px';
        imageRef.style.height = height + 'px';
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rows !== prevState.rows) {
      this.calcHeights();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.resizeObserver.observe(document.body);
    PortfolioApi.wedding(this.getType(), this.getId(),
      wedding => this.setState(wedding));
  }

  componentWillUnmount() {
    window.stop();
    window.removeEventListener('resize', this.handleWindowResize);
    this.resizeObserver.unobserve(document.body);
  }

  render() {
    const imagesHost = process.env.REACT_APP_IMAGES_HOST ? process.env.REACT_APP_IMAGES_HOST : '';
    const name = this.state.name;
    const rows = this.state.rows;
    const { language } = this.props;
    return (
      <>
        <div className="wedding">
          <div className="wedding-name">
            {parseWord(language, name)}
          </div>
          <div className="wedding-images" ref={this.weddingImagesRef}>
            {rows.map((row, index) =>
              <div key={index} className="wedding-row">
                {row.map((image, index) =>
                  <img key={index} alt={image.name + ' from wedding'} className="wedding-image"
                    src={imagesHost + join(ApiConfig.Portfolio, this.getId(), this.getType(), 'image', image.name)}
                    ref={r => this.imagesRef.set(image, r)}
                    onContextMenu={e => e.preventDefault()}
                    onDragStart={e => e.preventDefault()} />
                )}
              </div>
            )}
          </div>
        </div>
        <Copyright language={language} />
      </>
    );
  }
}

export default Wedding;