import React, { Component } from 'react';
import './Contacts.css';
import { parseWord } from '../app/Languages';
import Copyright from './Copyright';
import Name from '../app/Name';

class Contacts extends Component {
  state = {
    loaded: false,
    showPhoto: true,
  };

  handleTogglePhoto() {
    this.setState({
      showPhoto: !this.state.showPhoto,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        showPhoto: true,
      });
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    });
  }

  componentWillUnmount() {
    this.props.onSetPortfolioScrollPosition(0);
  }

  render() {
    const { showPhoto, loaded } = this.state;
    const { language } = this.props;
    if (!loaded) return <div />;
    return (
      <>
        <div className="contacts">
          <div className="contacts-photo-container" onClick={() => this.handleTogglePhoto()}
            onContextMenu={e => e.preventDefault()}
            onDragStart={e => e.preventDefault()}>
            <img alt="contacts" className={'contacts-photo' + (showPhoto ? '' : ' contacts-photo-hided')}
              src="/images/photo.jpg" />
            <img alt="contacts" className={'contacts-photo' + (showPhoto ? ' contacts-photo-hided' : '')}
              src="/images/photo2.jpg" />
          </div>
          <div className="contacts-data">
            <div className="contacts-contact">
              {parseWord(language, Name)}
            </div>
            <div className="contacts-contact">
              <a className="contacts-link" href="tel:+380966088636">+380 (96) 608 86 36</a>
            </div>
            <div className="contacts-contact">
              <a className="contacts-link" href="mailto: danylovych.yura@gmail.com">danylovych.yura@gmail.com</a>
            </div>
          </div>
        </div>
        <Copyright language={language} />
      </>
    );
  }
}

export default Contacts;