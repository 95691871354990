import React, { Component } from 'react';
import './Copyright.css';
import { parseWord } from '../app/Languages';
import Name from '../app/Name';

class Copyright extends Component {
  render() {
    const { language, className } = this.props;
    const year = new Date().getFullYear();
    return (
      <div className={'copyright ' + (className ? className : '')}>
        © {year} {parseWord(language, Name)}
      </div>
    )
  }
}

export default Copyright;