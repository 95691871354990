import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import Main from './components/Main';
import { createBrowserHistory } from 'history';
import GoogleAnalytics from './utils/GoogleAnalytics';

const history = createBrowserHistory();
const isGADisabled = JSON.parse(localStorage.getItem('DANYLOVYCH_COM_isGADisabled'));

if (!isGADisabled) {
  const ga = new GoogleAnalytics(process.env.REACT_APP_GA_IDENTIFIER);
  ga.run(history);
}

ReactDOM.render(
  <Router history={history}>
    <Main />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
